<template>
  <footer>
    <div class="content">
      <ul>
        <li class="unique">购物指南</li>
        <li>购物流程</li>
        <li>支付方式</li>
        <li>开具发票</li>
      </ul>
      <ul>
        <li class="unique">订单流程</li>
        <li>订单运费</li>
        <li>验货验收</li>
        <li>退换货流程</li>
      </ul>
      <ul>
        <li class="unique">关于我们</li>
        <li>公司简介</li>
        <li>联系我们</li>
      </ul>
    </div>

    <div style="margin-top: 10px;text-align: center">
      <div> Copyright©2023 {{shopName}}</div>
    </div>

    <div style="margin-top: 10px;margin-bottom:100px;text-align: center">
      <a href="https://beian.miit.gov.cn"> {{beian}}
      </a>
    </div>

  </footer>
</template>

<script>
import {
  getShopInfo,
} from "@/api/index.js";

export default {
  name: "footerCom",
  data() {
    return {
      shopName:'',
      beian:"",
    }
  },
  created() {
    this.beian = localStorage.getItem("beian");
    this.getShopInfo();
  },
  methods: {
    getShopInfo() {
      getShopInfo({'shopNo': localStorage.getItem('shopNo')}).then(res => {
        this.shopName = res.data.shopName;
      })
    },
  }
};


</script>

<style scoped lang="scss">
body {
  position: relative;
}

footer {
  width: 1200px;
  margin: 0 auto;
  // margin-top: 100px;
  height: 160px;
  background-color: #fff;
  padding: 0 50px;
}

.text {
  text-align: center;
  color: #aeaeae;
  font-size: 14px;
  margin: 50px 0;
  display: flex;
}

.content {
  cursor: pointer;
  height: inherit;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #aeaeae;
  font-size: 14px;
  letter-spacing: 1px;

  ul {
    list-style: none;
    height: 84px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .unique {
      font-size: 16px;
      font-weight: 900;
      margin: 10px 0;
    }

    li {
      display: flex;
      align-items: center;

      &:hover {
        color: red;
      }
    }
  }
}
</style>
