<template>
  <div class="goTop">
    <!-- 客服 -->
    <!-- <div
      class="service"
      @mouseenter="isShow = true"
      @mouseleave="isShow = false"
    >
      <div class="details" v-show="isShow">
        <ul>
          <li>贝利官方网络商城</li>
          <li>
            <span>在线客服</span>
            <div>
              <img src="../assets/img/service-icon.png" alt="" /> 联系客服
            </div>
          </li>
          <li>
            <span>工作时间</span>周一至周日: 10:00-22:00 （国家法定节假日除外）
          </li>
          <li><span>联系电话</span> 400-920-9299</li>
        </ul>
      </div>
    </div> -->
    <!-- 返回顶部 -->
    <el-backtop class="btn" :right="50" :bottom="100"
      ><i class="el-icon-caret-top"></i
    ></el-backtop>
  </div>
</template>

<script>
export default {
  name: "topCom",
  data() {
    return { isShow: false };
  },
};
</script>

<style scoped lang="scss">
.goTop {
  position: fixed;
  right: 50px;
  bottom: 100px;
  height: 118px;
  width: 56px;
  z-index: 999;
  .service {
    position: relative;
    z-index: 999;
    border: 1px solid #e5e5e5;
    background-color: #f2f2f2;
    height: 57.5px;
    background-image: url("../assets/img/service.png");
    background-size: contain;
    .details {
      position: absolute;
      z-index: 999;
      top: -138px;
      background-color: #f2f2f2;
      right: 56px;
      width: 210px;
      // height: 351px;
      border: 1px solid #e5e5e5;
      font-size: 14px;
      ul {
        li {
          padding: 15px;
          border-bottom: 1px solid #e5e5e5;
          box-sizing: border-box;
          span {
            display: block;
            font-size: 16px;
            margin-bottom: 5px;
          }
          div {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100px;
            height: 30px;
            background-color: white;
            // line-height: 30px;
            border-radius: 2px;
            img {
              height: 80%;
            }
          }
        }
      }
    }
  }
  .btn {
    width: inherit;
    height: 57.5px;
    border-radius: 0;
    background-image: url("../assets/img/top.png");
    box-sizing: border-box;
    background-size: contain;

    .el-icon-caret-top::before {
      content: "";
    }
  }
}
</style>
