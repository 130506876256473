import service from "@/utils/request";

export const getGoodsList = (data) => {
    return service({
        url: "goods/getGoodsList",
        method: "post",
        data: data
    })
}
export const getGoodsInfo = (data) => {
    return service({
        url: "goods/getGoodsInfo",
        method: "post",
        data: data
    })
}
export const getGoodsCate = (data) => {
    return service({
        url: "cate/getGoodsCateList",
        method: "post",
        data: data
    })
}
export const getBigCateList = (data) => {
    return service({
        url: "cate/getBigCateList",
        method: "post",
        data: data
    })
}
export const getSubCateList = (data) => {
    return service({
        url: "cate/getSubCateList",
        method: "post",
        data: data
    })
}
export const getBrandList = (data) => {
    return service({
        url: "brand/getBrandList",
        method: "post",
        data: data
    })
}
export const register = (data) => {
    return service({
        url: "user/register",
        method: "post",
        data: data
    })
}
export const login = (data) => {
    return service({
        url: "user/login",
        method: "post",
        data: data
    })
}
export const getShopImg = (data) => {
    return service({
        url: "shop/getShopImg",
        method: "post",
        data: data
    })
}
export const getShopInfo = (data) => {
    return service({
        url: "shop/getShopInfo",
        method: "post",
        data: data
    })
}

