// 引入axios对其进行二次封装
import axios from "axios";
const service = axios.create({
    baseURL:'http://47.98.186.122:8200/',
    timeout: 5000,
    headers: {
        Authorization: '541F98322EAEA41F2B2E3D023972F098'

    },
})
//3. 定义前置拦截器，请求拦截器，请求发送出去之前触发的
service.interceptors.request.use((config) => {
    //config 接口请求的配置信息
    return config;

}, (error) => {
    //报错的是时候抛出一个报错的信息
    return Promise.reject(error);
})
//4. 定义后置拦截器,响应拦截器, 服务器响应回来数据之前触发，
service.interceptors.response.use((response) => {

    //响应回来的数据操作
    return response.data;
}, (error) => {
    //报错的是时候抛出一个报错的信息
    return Promise.reject(error);
})

export default service