import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// 引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import tool from "@/utils/tools";

Vue.use(tool);
Vue.use(ElementUI);
require('./mock');
// 注册头部、底部、返回顶部全局组件
import HeaderCom from "./components/HeaderCom.vue";
import FooterCom from "./components/FooterCom.vue";
import TopCom from "./components/TopCom.vue";
Vue.component("HeaderCom", HeaderCom);
Vue.component("FooterCom", FooterCom);
Vue.component("TopCom", TopCom);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
