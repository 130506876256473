<template>
  <div class="page_warp">
    <div class="box">
      <div>
        <!-- <div class="block"> -->
        <el-carousel height="400px" width="1400px" trigger="click" indicator-position="none" arrow="never">
          <el-carousel-item v-for="item in banner" :key="item">
            <img :src="item" alt="" style="height: 400px;width: 1400px;"/>
          </el-carousel-item>
        </el-carousel>
        <!-- </div> -->
        <div class="menu">
          <HideMenu/>
        </div>
      </div>

    </div>
    <div class="home">
      <div class="qmBar" @click="toDoods">
        <img :src="img" alt="">
      </div>
      <div class="qmBar" @click="toDoods">
        <img src="https://smbsrv.obs.cn-east-2.myhuaweicloud.com/202212221103098316.jpg
" alt="">
      </div>
      <div class="qmBar" style="height: 155px;" @click="toDoods">
        <img style="height: 155px;" src="https://smbsrv.obs.cn-east-2.myhuaweicloud.com/202212061124226961.jpg
" alt="">
      </div>
      <!-- 商品1 -->
      <GoodsList :newArr='firstArr'/>
      <div class="qmBar" style="height: 155px;" @click="toDoods">
        <img style="height: 155px;" src="	https://smbsrv.obs.cn-east-2.myhuaweicloud.com/202212061124226346.jpg
" alt="">
      </div>
      <!-- 商品2 -->
      <GoodsList :newArr='secondArr'/>
      <div class="qmBar" style="height: 155px;" @click="toDoods">
        <img style="height: 155px;" src="	https://smbsrv.obs.cn-east-2.myhuaweicloud.com/202212061124222748.jpg
" alt="">
      </div>
      <!-- 商品3 -->
      <GoodsList :newArr='thirdArr'/>
      <div class="qmBar" style="height: 96px;" @click="toDoods">
        <img style="height: 96px;" src="https://smbsrv.obs.cn-east-2.myhuaweicloud.com/202212061127015602.jpg" alt="">
      </div>
    </div>
  </div>

</template>

<script>
import GoodsList from '@/components/IndexGoodsList.vue';
import HideMenu from "@/components/HideMenu.vue"
import {
  getGoodsList,
  getShopImg,
} from "@/api/index.js";

export default {
  name: "HomeView",
  data() {
    return {
      // 轮播图
      carouselArr: [{
        id: 1,
        path: 'https://mallsaasbucket.oss-cn-hangzhou.aliyuncs.com/app/945428986187.png'
      },
        {
          id: 2,
          path: 'https://mallsaasbucket.oss-cn-hangzhou.aliyuncs.com/app/117413430335.png'
        },
      ],
      firstArr: [],//商品1
      secondArr: [],//商品2
      thirdArr: [],//商品3
      banner:[],
      img:"",
    };
  },
  methods: {
    getShopImg() {
      getShopImg({'shopNo':localStorage.getItem('shopNo')}).then(res => {
        let data = res.data;
        this.banner = data.banner;
        this.img = data.img;
        console.log('img',data)
      })
    },
    async getGoodsList() {
      await getGoodsList({
        cateId: '',
        brandId: '',
        goodsName: '',
        pageNumber: 1,
        pageSize: 50
      }).then(res => {
        console.log('getGoodsList', res);
        this.firstArr = res.data.list.slice(0, 16)
        this.secondArr = res.data.list.slice(17, 33)
        this.thirdArr = res.data.list.slice(34, 50)
      })


    },
    toDoods() {
      this.$router.push({
        name: "commodity",
      });
    }
  },
  components: {
    GoodsList,
    HideMenu
  },
  // vue周期：创建
  created() {
    this.getShopImg();
    this.getGoodsList()
  },
};
</script>

<style scoped lang="scss">
.page_warp {
  z-index: -1;

  .box {
    position: relative;

    .menu {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;
      width: 1200px;
      height: 400px;
      margin: 0 auto;
      background: transparent;
    }
  }

  .home {
    width: 1220px;
    margin: 0 auto;


    .qmBar {
      width: 100%;
      height: 320px;
      margin-top: 15px;

      img {
        width: 100%;
        height: 320px;
      }
    }
  }

}
</style>