<template>
  <div>
    <header>
      <div class="line">
        <div class="content1">
          <router-link to="/auth">
            <div class="login">登录/注册</div>
          </router-link>
          <div class="navlist">
            <ul>
              <li>
                <router-link to="/messages">基本信息</router-link>
              </li>
              <li>
                <router-link to="/myorder">我的订单</router-link>
              </li>
              <li>账户安全</li>
              <li>消息</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <!-- logo -->
        <!-- <a href="" class="logo"><img src="../assets/img/logo.png" alt="" /></a> -->
        <router-link to="/" class="logo">
          <img :src="logo" alt=""/></router-link>
        <!-- 搜索模块 -->
        <div class="search-box">
          <input type="text" placeholder="输入商品名或货号" v-model="inputVal" @keydown.enter="search(inputVal)"/>
          <button @click="search(inputVal)"></button>
        </div>
        <!-- 购物车按钮 -->
        <div class="cart">
          <div>
            <router-link to="/cart">
              <!-- <img src="../assets/img/cart.png" alt="" /> -->
              <span>购物车</span>
              <span class="cart_num">{{ num }}</span></router-link>
          </div>

        </div>
      </div>
      <div class="content">
        <!-- 导航按钮 -->
        <nav>
          <div class="menu">
            <div @click="mouseover">
              <router-link class="unique" to="">
                <div>全部类目</div>
                <div><i class="el-icon-arrow-down"></i></div>
              </router-link>
            </div>
          </div>
          <router-link :class="activeIndex == index ? 'actives' : ''" :to="item.path" v-for="(item, index) in tabs"
                       :key="index"
                       @click.native="changeTabs(index)">{{ item.name }}
          </router-link>
        </nav>
        <!-- <div class="hide" :style="{display:show}">
          <HideMenu ref="menu" />
        </div> -->
      </div>
    </header>
  </div>
</template>

<script>
import {
  getShopInfo,
} from "@/api/index.js";

// import HideMenu from './HideMenu.vue';
export default {
  name: "headerCom",
  components: {},
  computed: {
    num() {
      let num = 0;
      num = this.$store.state.cartArr.length
      return num;
    },
  },
  data() {
    return {
      logo:'',
      activeIndex: "0",
      show: 'none',
      inputVal: '',
      tabs: [{
        name: '首页',
        path: '/'
      },
        {
          name: '全部商品',
          path: '/commodity'
        },
        {
          name: '采购专区',
          path: '/commodity'
        },
        {
          name: '关于我们',
          // path: '/cement'
          path: '/announcement'
        }
      ]
    };
  },
  created() {
    this.getShopInfo();
  },
  methods: {
    getShopInfo() {
      getShopInfo({'shopNo': localStorage.getItem('shopNo')}).then(res => {
        this.logo = res.data.shopLogo;
      })
    },
    changeTabs(index) {
      this.activeIndex = index
    },
    search(val) {
      this.$router.push(`${this.$route.path}`)
      // this.$router.push({
      //   name: 'commodity',
      //   params: { goodsName: val }, // 参数传值
      // });
      // console.log("传递" + val);

      // 不在当前页面
      if (this.$route.name !== "commodity") {
        this.$router.push({
          name: 'commodity',
          params: {goodsName: val}, // 参数传值
        });
        console.log("传递" + val);
      } else {
        this.$store.commit('search', val)
        //   localStorage.setItem('searchName',val)
        // this.$router.push(`${this.$route.path}?goodsName=${val}`)
        this.$router.push(`${this.$route.path}?goodsName=${val}`)
      }
    },
    mouseover() {
      this.$router.push({
        name: "home",
      });
    },
    mouseleave() {
      this.show = 'none'
    }
  },
};
</script>

<style scoped lang="scss">
body {
  height: 150vh;
}


div {

  // height: 60px;
  header {
    // position: fixed;
    // position: absolute;
    // top: 0px;
    // left: 0;
    // right: 0;
    height: 180px;
    white-space: nowrap;
    border-bottom: solid 1px #e5e5e5;
    border-top: solid 1px #e5e5e5;
    background-color: white;
    z-index: 999;

    .line {
      width: 100%;
      height: 50px;
      border-bottom: solid 1px #e5e5e5;

      .content1 {
        display: flex;
        text-align: center;
        line-height: 50px;
        align-items: center;
        justify-content: space-between;
        max-width: 1200px;
        height: 50px;
        margin: auto;
        box-sizing: border-box;

        .login {
          color: red;
          font-size: 14px;
          cursor: pointer;
        }

        .navlist {
          ul {
            font-size: 14px;
            color: gray;

            li {
              // width: 80px;
              margin: 0 10px;
              float: left;
              cursor: pointer;

              a {
                font-size: 14px;
                color: gray;

                &:hover {
                  color: #f55;
                }
              }

            }

          }
        }
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1200px;
      height: 70px;
      margin: auto;
      box-sizing: border-box;
      position: relative;

      .hide {
        position: absolute;
        top: 55px;
        left: 0;
        z-index: 99;
      }

      .logo {
        padding-top: 20px;
        display: block;
        width: 120px;
        height: 60px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .city {
        width: 64px;
        height: 30px;
        margin: 0 15px 0 25px;
        outline: none;
        border: solid 1px #e5e5e5;
      }

      nav {
        display: flex;
        gap: 20px;
        line-height: 40px;
        position: relative;

        a {
          position: relative;
          display: block;
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          font-weight: 900;

          &.actives {
            color: #f55 !important;
          }
        }

        .menu {
          // position: relative;

          .unique {
            width: 200px;
            background-color: #000;
            color: #fff;
            display: flex;
            justify-content: space-between;
            // align-items: center;
          }
        }
      }

      .search-box {
        position: relative;

        display: flex;
        align-items: center;
        height: 38px;

        input {
          width: 500px;
          border-radius: 3px;
          height: 38px;
          border: 1px solid #e5e5e5;
          outline: none;
          text-indent: 1em;

          &:focus {
            border-color: #7f0019;
          }
        }

        button {
          position: absolute;
          right: 1px;
          top: 0px;
          width: 38px;
          height: 38px;
          border: none;
          border-radius: 0 3px 3px 0;

          cursor: pointer;
          background-image: url("../assets/img/search-btn.png");
          background-size: contain;
        }
      }

      .cart {
        cursor: pointer;
        display: table-cell;
        vertical-align: middle;
        width: 110px;
        height: 36px;
        border: 1px solid #ddd;
        text-align: center;
        line-height: 36px;

        span {
          // display: block;
          font-size: 12px;
          text-align: center;
          margin-right: 5px;
        }

        .cart_num {
          color: #fff;
          background-color: #cb4255;
          display: inline-block;
          width: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 12px;
          border-radius: 50%;
        }
      }
    }
  }
}</style>