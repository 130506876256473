<template>
 <div class="goods">
        <div class="new box">
        <ul>
          <li v-for="el in newArr" :key="el.goodsId" >
            <router-link :to="`/commodity/${el.goodsId}`">
            <div class="pic">
              <img :src="el.goodsImg" alt="" />
            </div>
            <p class="shop_title">{{ el.goodsName }}</p>
            <p></p>
            <p>
              <span class="price">￥{{ el.marketPrice }}元</span><span class="originalCost"> {{ el.marketPrice }}元</span>
            </p>
            </router-link >
          </li>
        </ul>
      </div>
 </div>
</template>

<script>
 import axios from 'axios'
export default {
  name: "GoodsList",
  props:{
    newArr:{
        type:Array,
        default:()=>[]
    }
  },
  data() {
    return{
        // newArr:[]
    }
  },
  methods:{
  
  },
  created(){
       
  }
};
</script>

<style scoped lang="scss">
.goods{
    .box {
        margin-bottom: 50px;
        margin-top: 20px;
        font-size: 14px;

        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          li {
            width: 283px;
            height: 350px;
            border: 1px solid #e5e5e5;
            border-radius: 3px;
            cursor: pointer;
            margin: 10px 0;
            // padding:10px;
            &:hover {
              box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
            }

            .pic {
              img {
                width: 100%;
                height: 100%;
              }
            }

            .shop_title {
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            p{
              margin: 10px 0;
              .price{
                font-size: 16px;
                color:#f55;
                margin-right: 5px;
              }
              .originalCost{
                font-size: 12px;
                color:gray;
                text-decoration: line-through;
              }
            }
          }
        }
      }
}
</style>
