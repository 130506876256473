<template>
    <div class="menu">
        <div class="hide_menu">
            <ul class="menu_list">
                <li @mouseover="mouseOver(el.cateId)" @mouseleave="mouseLeave" v-for='el in navListDatas'
                    :key='el.cateId'>
                    <div class="item">
                        <div>{{ el.cateName }}</div>
                        <div><i class="el-icon-arrow-right"></i></div>
                    </div>
                </li>
            </ul>
            <div class="box" :style="{display:unShow}" @mouseover="moveIn" @mouseleave="moveOut">
                <div class="cate" v-for="ele in ListDatas" :key="ele.cateId">
                    <div class="title">{{ele.cateName}}</div>
                    <div class="tags">
                        <div class="tag" v-for="item in ele.subList" @click="toGoods(item.cateName)" :key="item.id">{{item.cateName}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    // import { navListDatas } from "@/mock/navDatas"
    import {
        getGoodsCate,getBigCateList,getSubCateList
    } from "@/api/index"
    import $ from 'jquery'
    export default {
        name: "topCom",
        data() {
            return {
                show: 'block',
                unShow: 'none',
                active: 'none',
                activeAll: 'block',
                navListDatas: [],
                ListDatas:[],
                activeIndex: '-1',
                cateId:'',
            };
        },
        created() {
            // this.getGoodsCate()
            this.getBigCateList()
        },
        mounted() {},
        methods: {

            // 获取小分类列表
            async getSubCateList() {
                await getSubCateList({
                    cateId:this.cateId
                }).then(res => {
                    console.log(res);
                    this.ListDatas = res.data
                })

            },
            async getBigCateList(){
                await getBigCateList({}).then(res=>{
                      console.log(res);
                    this.navListDatas = res.data
                })
            },
            toGoods(cateName){
                this.$router.push({
          name: 'commodity',
          params: { goodsName: cateName }, // 参数传值
        });
            },
            mouseOver(cateId) {
                this.cateId = cateId
                this.getSubCateList()
                this.unShow='block'
                // this.active = 'block'
                // this.activeAll = 'block'
            },
            in(){
                 this.unShow='block'
            },
            mouseLeave() {
                this.unShow = 'none'
            },
              moveIn() {
                this.unShow = 'block'
              },
              moveOut() {
                this.unShow = 'none'
              },
        }
    };
</script>

<style scoped lang="scss">
    .menu {
        .hide_menu {
            height: 405px;
            // width: 220px;
           position: relative;

            .menu_list {
                 width: 220px;
                height: 405px;
                background: rgba(0, 0, 0, .7);
                overflow: auto; // 超出这个最大高度的数据，会被隐藏起来，上下滑动
                overflow-x: hidden; //隐藏x轴滚动条

                &::-webkit-scrollbar {
                    display: none;
                }

                li {
                    width: 220px;

                    .item {
                        display: flex;
                        justify-content: space-between;
                        height: 38px;
                        cursor: pointer;
                        line-height: 38px;
                        padding: 0 15px;
                        color: #fff;
                        font-size: 14px;
                        border-bottom: 1px solid hsla(0, 0%, 100%, .05);

                        &:hover {
                            background: #fff;
                            color: #60b3fa;
                        }
                    }


                }
            }

            .box {
                position: absolute;
                width: 1000px;
                height: 405px;
                left: 220px;
                top: 0;
                background: #fff;
                z-index: 9999999999;
                padding: 15px;
                    overflow: auto; // 超出这个最大高度的数据，会被隐藏起来，上下滑动
                overflow-x: hidden; //隐藏x轴滚动条

                &::-webkit-scrollbar {
                    display: none;
                }
                .cate{
                    width: 100%;
                    display: flex;
                    justify-content:flex-start;
                    .title{
                        width:80px;
                        font-size: 14px;
                        color:#555;
                        font-weight: 900;
                        margin-top: 10px;
                    }
                    .tags{
                        cursor: pointer;
                        font-size: 12px;
                        color: gray;
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        .tag{
                            margin:10px;
                            &:hover{
                                color:#f55;
                            }
                        }
                    }
                }
            }
        }

    }
</style>