import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 购物车数组
    cartArr: JSON.parse(localStorage.getItem("carts")) ? JSON.parse(localStorage.getItem("carts")) : [],
    // 收藏夹数组
    favArr: [],
    // 该商品是否收藏
    isCollect: false,
    inputVal:'',
  },
  getters: {
    // sendBuy(state,step){
    //   state.cartArr.push(step)
    // }
    // // 传送购物车数组
    // getCartArr(state) {
    //   return state.cartArr;
    // },
    // // 传送收藏夹数组
    // getFavArr(state) {
    //   return state.favArr;
    // },
  },
  mutations: {
    search(state,val){
      state.inputVal=val
    },
    sendBuy(state,step){
      var cartArr = state.cartArr;
      var flag = true;
      // 判断购物车中是否已经存在该商品
      for(var i=0;i<cartArr.length;i++){
          if(cartArr[i].goodsId ==step.goodsId){
              // 存在则数量加1
              cartArr[i].num++;
              flag = false;
              break;
          }
      }
      // 购物车中不存在该商品-首次添加
      if(flag){
          // goods新增选中状态默认未选中,数量默认1
          Vue.set(step,'num',1);
          Vue.set(step,'selected',false);
          cartArr.push(step);
      }
      // state.cartArr.push(step)
      localStorage.setItem("carts", JSON.stringify(state.cartArr))
    },
        // 删除一个
        deleCart(state, data) {
          state.cartArr.splice(data,1)//通过splice进行删除
				localStorage.setItem('carts',JSON.stringify(state.cartArr))//在重新存储一次
      },
  },

  actions: {},
  modules: {},
});
